const initialState = {
    data: [],
    total: 0,
    loader: true
}

export const pmpDeals = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_DEALS':
            return { ...state, data: action.data, total: action.total, loader: false }
        case 'ADD_DEAL':
            return { ...state }
        case 'UPDATE_DEAL':
            return { ...state }
        case 'GET_DEAL':
            return { ...state, data: action.data, loader: false }
        default:
            return { ...state }
    }
}
