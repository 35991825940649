const initialState = {
    data: [],
    loader: true,
    userData: [],
    advertiserData: [],
    exchangeData: [],
    bundleData: [],
    totalPages: null,
    total: 1,
    loader: true,
    campaigns: [],
    sspData: [],
    isSubmitting: true,
    campaignTotal: {},
    creativeTotal: {}
}

export const reportStats = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('GET_DEVICE_REPORT'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('GET_PUBLISHER_REPORT'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('GET_SITEAPP_V2_REPORT'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case 'GET_CAMP_BY_ID':
            return { ...state, campaigns: action.data }
        case 'GET_CREATIVE_BY_ID':
            return { ...state, creatives: action.data }
        case ('GET_CREATIVE_REPORT'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case 'GET_USERS':
            return { ...state, userData: action.data, loader: false }
        case 'GET_ADVERTISER':
            return { ...state, advertiserData: action.data, loader: false }
        case ('GET_GEO_REPORT'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('GET_TABULAR_REPORT'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('GET_ADVERTISER_REPORT'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('GET_SSP_REPORT'):
            return { ...state, sspData: action.data, loader: false }
        case 'EXPORT_REPORT':
            return { ...state, isSubmitting: false }
        case ('GET_EXCHANGE_LIST'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('SEARCH_EXCHANGE'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case ('GET_EXCHANGE_ID'):
            return { ...state, exchangeData: action.data }
        case ('GET_EXCHANGE_BUNDLE'):
            return { ...state, bundleData: action.data }
        case ('GET_CAMPAIGN_REPORT_TOTAL'):
            return { ...state, campaignTotal: action.campaignTotal }
        case ('GET_CREATIVE_REPORT_TOTAL'):
            return { ...state, creativeTotal: action.creativeTotal }
        case 'RESET_REDUCER_STATE':
            return initialState
        default:
            return { ...state }
    }

}
