const initialState = {
  data: [],
  totalPages: 1,
  total: 0,
  params: {},
  selectedAudiences: null,
  mmp: [],
  bundleId: [],
  events: [],
  eventsList: [],
  eventCount: {},
  loader: true,
  url: ''
}

export const audiences = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_AUD_DATA':
      return { ...state, data: action.data, totalPages: action.totalPages, total: action.total, loader: false }
    case 'GET_ALL_AUD_EVENT':
      return { ...state, events: action.data, loader: false }
    case 'GET_MMP_LIST':
      return { ...state, mmp: action.data, loader: false }
    case 'GET_BUNDLE_LIST':
      return { ...state, bundleId: action.data, loader: false }
    case 'GET_EVENTS_LIST':
      return { ...state, eventsList: action.data, loader: false }
    case 'EDIT_AUD':
      return { ...state, selectedAudiences: action.selectedAudiences }
    case 'ADD_AUD':
      return { ...state }
    case 'GET_AUD_SEARCH':
      return { ...state, data: action.data, totalPages: action.totalPages, loader: false }
    case 'UPDATE_AUD':
      return { ...state }
    case 'DELETE_AUD':
      return { ...state }
    case 'GET_EVENT_COUNT':
      return { ...state, eventCount: action.data }
    case 'GENERATE_URL':
      return { ...state, url: action.data }
    case 'RESET_REDUCER_STATE':
      return initialState
    default:
      return { ...state }
  }
}