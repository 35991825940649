const initialState = {
    data: [],
    total: 0,
    loader: true,
    reportData: null,
    creative: []
}

export const customReports = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_CUSTOM_REPORTS':
            return { ...state, data: action.data, total: action.totalPages, loader: false }
        case 'GET_CUSTOM_REPORT':
            return { ...state, reportData: action.reportData }
        default:
            return { ...state }
    }
}