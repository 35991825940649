// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'
// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import 'rsuite/dist/rsuite.min.css'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './@core/css/styles.css'
import './@core/css/daypart.css'

import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import EventSourceProvider from './utility/context/EventSourceContext'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <AbilityContext.Provider value={ability}>
                <EventSourceProvider>
                    <ThemeContext>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                    </ThemeContext>
                </EventSourceProvider>
            </AbilityContext.Provider>
        </Suspense>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
