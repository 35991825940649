const initialState = {
  allData: [],
  creativeFData: [],
  campaignFData: [],
  total: 0,
  selectedCreative: null,
  campaignAttch: [],
  totalPages: 1,
  loader: true
}

export const creativeManager = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data, total: action.total, totalPages: action.totalPages, loader: action.loader }
    case 'GET_DATA':
      return {
        ...state,
        allData: action.data,
        total: action.total,
        totalPages: action.totalPages
      }
    case 'GET_CREATIVE':
      return { ...state, allData: action.data, selectedCreative: action.selectedCreative, creativeData: action.data, totalPages: action.totalPages, total: action.total, loader: action.loader }
    case 'GET_CAMP_FILTER':
      return { ...state, campaignFData: action.campaignFData }
    case 'GET_CRTV_FILTER':
      return { ...state, creativeFData: action.creativeFData }
    case 'GET_CAMP_ATTACH':
      return { ...state, campaignAttch: action.campaignAttch }
    default:
      return { ...state }
  }
}
