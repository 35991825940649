const UserConfig = () => {
    const userCustomFeatures = localStorage.getItem("userCustomFeatures")?.split(",") || []
    const uiConfiguration = JSON.parse(localStorage.getItem("uiConfiguration")) || {}

    // Cumulative fiilter not allowed
    const excludeCumulativeFilter = uiConfiguration.cumulativeNotAllowed
    // ERID token is needed for this user in Campaigns
    const includeErIdToken = uiConfiguration.erIdTokenRequired?.split(",") || []
    // Remove Siteapp Report
    const excludeSiteappReports = uiConfiguration.siteappReportNotAllowed
    // Remove exchange from filter
    const excludeExchangeFilter = uiConfiguration.exchangeFilterNotAllowed
    // Remove Install Column
    const excludeInstall = uiConfiguration.installColumnNotAllowed
    // Remove RegistrationEvents Column
    const excludeRegistrationEvents = uiConfiguration.registrationEventsColumnNotAllowed
    // Remove PurchaseEvents Column
    const excludePurchaseEvents = uiConfiguration.purchaseEventsColumnNotAllowed
    // Remove RepeatEvents Column
    const excludeRepeatEvents = uiConfiguration.repeatEventsColumnNotAllowed
    // Remove ECPI Column
    const excludeECPI = uiConfiguration.ecpiColumnNotAllowed
    // Remove ECPA Column
    const excludeECPA = uiConfiguration.ecpaColumnNotAllowed
    // Allow needs to be shown in targeting when Audience is disabled from customFeatures
    const includeAudienceTargeting = uiConfiguration.audienceTargetingNeeded
    // Allow Device Report
    const includeDeviceReporting = uiConfiguration.deviceReportAllowed
    // Quartile Columns allowed
    const includeQuartileColumns = uiConfiguration.quartileColumnsAllowed

    // Show deals section in inventory
    const dealEnabled = userCustomFeatures.includes("DEAL")
    // Allow user to see audience section in campaign targeting
    const audienceEnabled = userCustomFeatures.includes("AUDIENCE")
    // Allow user to see audience section in campaign targeting
    const bidMultiplierEnabled = userCustomFeatures.includes("BID MULTIPLIER")
    // 
    const internalAudienceEnabled = userCustomFeatures.includes("INTERNAL")
    // Allow VideoCompletion Column
    const videoReportingEnabled = userCustomFeatures.includes("VIDEO REPORTING")
    // Allow user for 2FA -> UserDropdown
    const twoFAEnabled = userCustomFeatures.includes("2FA")
    // Show only Reports section to user
    const onlyReportingEnabled = userCustomFeatures.includes("ONLY REPORTING")
    // Allow both WEB & APP
    const webAndAppEnabled = userCustomFeatures.includes("WEB & APP")
    // Show Video Completion in Creative Section
    const videoTrackingEnabled = userCustomFeatures.includes("VIDEO TRACKING")

    return {
        excludeCumulativeFilter,
        includeErIdToken,
        excludeSiteappReports,
        excludeExchangeFilter,
        excludeInstall,
        excludeRegistrationEvents,
        excludePurchaseEvents,
        excludeRepeatEvents,
        excludeECPI,
        excludeECPA,
        includeAudienceTargeting,
        includeDeviceReporting,
        includeQuartileColumns,
        dealEnabled,
        audienceEnabled,
        bidMultiplierEnabled,
        internalAudienceEnabled,
        videoReportingEnabled,
        twoFAEnabled,
        onlyReportingEnabled,
        webAndAppEnabled,
        videoTrackingEnabled
    }
}

export default UserConfig