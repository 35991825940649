const initialState = {
    status: 0,
    str_status: '',
    email: '',
    message: [],
    loader: true
}

export const commons = (state = initialState, action) => {
    switch (action.type) {
        case 'REG_USER':
            return { ...state, status: action.status, email: action.data.username }
        case 'FORGOT_PASSWORD':
            return { ...state, message: [action.message], str_status: action.status }
        case 'RESET_PASSWORD_ERROR':
            return { ...state, message: action.message }
        default:
            return { ...state }
    }

}
