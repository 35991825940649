const initialCampState = {
    allData: [],
    brands: [],
    total: 1,
    selectedCampaign: null,
    selectedBrand: null,
    loader: true
}

export const campaigns = (state = initialCampState, action) => {
    switch (action.type) {
        case 'GET_ALL_CAMP_DATA':
            return { ...state, allData: action.data, total: action.totalPages, loader: false }
        case 'GET_CAMP':
            return { ...state, allData: action.data, total: action.totalPages, loader: false }
        case 'GET_CAMP_DATA':
            return { ...state, allData: action.data, total: action.totalPages }
        case 'GET_CAMPAIGN':
            return { ...state, selectedCampaign: action.selectedCampaign }
        case 'GET_ALL_BRANDS':
            return { ...state, brands: action.data }
        case 'GET_BRAND':
            return { ...state, selectedBrand: action.selectedBrand }
        case 'DELETE_BRAND':
            return { ...state }
        case 'UPDATE_BRAND':
            return { ...state }
        case 'ADD_BRAND':
            return { ...state }
        case 'ADD_CAMP':
            return { ...state }
        case 'UPDATE_CAMP':
            return { ...state }
        case 'GET_CAMP_FILTER':
            return { ...state, campaignFData: action.campaignFData }
        case 'GET_CAMP_CLONE':
            return { ...state }
        case 'RESET_REDUCER_STATE':
            return initialCampState
        default:
            return { ...state }
    }
}
