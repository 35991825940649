const initialAudState = {
    data: [],
    getData: null,
    loader: true,
    total: 1
}

export const appsflyerAudience = (state = initialAudState, action) => {
    switch (action.type) {
        case 'GET_ALL_AUD_DATA':
            return { ...state, data: action.data, loader: false }
        case 'GET_AUD':
            return { ...state, getData: action.data, loader: false}
        case 'CREATE_AUD':
            return { ...state }
        case 'UPDATE_AUD':
            return { ...state }
        default:
            return { ...state }
    }
}