const initialState = {
    data: [],
    total: 1,
    totalPages: 0,
    selectedData: {},
    loader: true
}

export const eventLogs = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ALL_EVENT_LOGS':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case 'SEARCH_EVENT_LOGS':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        default:
            return { ...state }
    }
}
