// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import { campaigns } from '@src/views/manage/campaigns/data/store/reducer'
import { applists } from '@src/views/assets/applists/data/store/reducer'
import { audiences } from '@src/views/assets/audience/data/store/reducer'
import { creativeLists } from '@src/views/assets/creatives/data/store/reducer'
import { dashboardStats } from '@src/views/analyse/dashboard/store/reducer'
import { adminDashboardStats } from '@src/views/analyse/admin-dashboard/store/reducer'
import { reportStats } from '@src/views/reports/store/reducer'
import { commons } from '@src/views/commons/store/reducer'
import { manageUsers } from '@src/views/settings/manageUser/data/store/reducer'
import { manageReports } from '@src/views/settings/manageReports/store/reducer'
import { appsflyerAudience } from '@src/views/settings/appsflyerAudience/store/reducer'
import { mmpSettings } from '../../views/settings/mmpSettings/data/reducer'
import { billing } from '../../views/accounts/data/reducer'
import { userAccounts } from '../../views/manage/userAccounts/data/store/reducer'
import { campaignManager } from '@src/views/manage/campaignManager/data/store/reducer'
import { creativeManager } from '@src/views/manage/creativeManager/data/store/reducer'
import { optimization } from '../../views/tools/optimization/data/store/reducer'
import { siteapps } from '@src/views/manage/inventory/data/store/reducer'
import { bidMultiplier } from '../../views/tools/bidMultiplier/data/store/reducer'
import { bidderSource } from '../../views/tools/bidderDS/data/store/reducer'
import { eventLogs } from '../../views/tools/eventLogs/data/store/reducer/index'
import { pmpDeals } from '../../views/tools/pmpDeals/data/store/reducer'
import { exportLogs } from '../../views/tools/exportLogs/data/store/reducer'
import { customReports } from '@src/views/reports/customReport/data/store/reducer'

const rootReducer = combineReducers({
    auth,
    navbar,
    layout,
    applists,
    campaigns,
    audiences,
    creativeLists,
    dashboardStats,
    reportStats,
    commons,
    manageUsers,
    campaignManager,
    creativeManager,
    adminDashboardStats,
    manageReports,
    appsflyerAudience,
    mmpSettings,
    billing,
    userAccounts,
    optimization,
    siteapps,
    bidMultiplier,
    bidderSource,
    eventLogs,
    pmpDeals,
    exportLogs,
    customReports
})

export default rootReducer
