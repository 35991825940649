const initialState = {
    data: [],
    total: 1,
    totalPages: 0,
    selectedData: {},
    loader: true
}

export const bidderSource = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_FILEINFO':
            return { ...state, data: action.data, loader: false }
        default:
            return { ...state }
    }
}
