// You can customize the template with the help of this file

import { useLocation } from 'react-router-dom'

//Template config options

const themeConfig = {
  app: {
    appName: '',
    appLogoImage: window.location.hostname === 'lyxelflamingo.mobavenue.com' ? require('@src/assets/images/logo/lflogo.png').default : require('@src/assets/images/logo/logo.png').default,
    loginLogoImage: window.location.hostname === 'lyxelflamingo.mobavenue.com' ? require('@src/assets/images/logo/lf-logo-dark.png').default : require('@src/assets/images/logo/Mobavenue_Logo.svg').default
  },
  layout: {
    isRTL: false,
    skin: 'semi-dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

const a = () => {
  const loc = useLocation()
  console.log("loc: ", loc)
}

export default themeConfig
