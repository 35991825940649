const initialState = {
    data: [],
    total: 1,
    loader: true,
    accounts: []
}

export const manageUsers = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USERS':
            return { ...state, data: action.data, total: action.total, loader: false }
        case 'GET_ACC_MANAGER':
            return { ...state, accounts: action.accounts }
        case 'UPDATE_USERS':
            return { ...state }
        default:
            return { ...state }
    }
}
