const initialState = {
    data: [],
    txnData: [],
    userData: {},
    total: 1,
    loader: true
}

export const billing = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BILLING':
            return { ...state, data: action.data, loader: false }
        case 'GET_TXN':
            return { ...state, txnData: action.data, loader: false }
        case 'GET_USER':
            return { ...state, userData: action.data, loader: false }
        case 'UPDATE_PASSWORD':
            return { ...state, loader :false }
        default:
            return { ...state }
    }
}