const initialState = {
    data: [],
    userData: [],
    getData: null,
    loader: true,
    total: 1
}

export const manageReports = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL':
            return { ...state, data: action.data, loader: false}
        case 'GET_USERS':
            return { ...state, userData: action.data, loader: false}
        case 'CREATE_SETTINGS':
            return { ...state, data: action.data, loader: false}
        case 'GET_SETTING':
            return { ...state, getData: action.data, loader: false}
        case 'UPDATE_SETTINGS':
            return { ...state }
        default:
            return { ...state }
    }
}