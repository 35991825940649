import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

//NOTE: currently we are not getting ability data from Backend API response
//const userData = JSON.parse(localStorage.getItem('userData'))
//const existingAbility = userData ? userData.ability : null
export default new Ability(initialAbility(localStorage.getItem('roleId')))
