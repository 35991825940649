// ** Initial State
const initialState = {
    data: [],
    impressions: [],
    clicks: [],
    bids: [],
    spends: [],
    params: {},
    estimate: {},
    total: 1,
    totalPages: 0,
    loader: true
}

export const adminDashboardStats = (state = initialState, action) => {
    switch (action.type) {
        case ('GET_LINE_STATS'):
            if (action.params?.type === 'impressions') {
                return { ...state, impressions: action.data, params: action.params, loader: false }
            } else if (action.params?.type === 'clicks') {
                return { ...state, clicks: action.data, params: action.params, loader: false }
            } else if (action.params?.type === 'spends') {
                return { ...state, spends: action.data, params: action.params, loader: false }
            } else {
                return { ...state, bids: action.data, params: action.params, loader: false }
            }
        case ('GET_TABULAR_STATS'):
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, params: action.params, loader: false }
        case ('GET_ESTIAMTE_STATS'):
            return { ...state, estimate: action.data, loader: false }
        default:
            return { ...state }
    }

}
