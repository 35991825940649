const initialState = {
    data: [],
    total: 1,
    totalPages: 0,
    selectedData: {},
    loader: true
}

export const bidMultiplier = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_BID':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case 'CREATE_BID':
            return { ...state }
        case 'GET_BID':
            return { ...state, selectedData: action.data, loader: false }
        case 'UPDATE_SETTING':
            return { ...state }
        case 'SEARCH_BIDS':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false}
        default:
            return { ...state }
    }
}
