import React, { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { SERVER_URL } from '@src/configs/config'
import { SuccessToastMsg } from '../Utils'

const EventSourceContext = createContext()

export const useEventSource = () => useContext(EventSourceContext)

const EventSourceProvider = ({ children }) => {
    const [eventSource, setEventSource] = useState(null)
    const userId = localStorage.getItem('userId')

    useEffect(() => {
        if (!eventSource && userId) {
            const source = new EventSource(`${SERVER_URL}/events/${userId}`)
            setEventSource(source)
        }
        if (eventSource) {
            eventSource.onmessage = (event) => {
                toast.success(<SuccessToastMsg msg={event.data} />)
            }

            eventSource.onerror = (err) => {
                console.error('EventSource failed:', err)
                eventSource.close()
            }

            return () => eventSource.close()
        }
    }, [eventSource])

    return (
        <EventSourceContext.Provider value={{ eventSource, setEventSource }}>
            {children}
        </EventSourceContext.Provider>
    )
}

export default EventSourceProvider
