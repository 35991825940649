const initialState = {
  allData: [],
  creativeFData: [],
  campaignFData:[],
  total: 0,
  selectedCreative: null,
  campaignAttch:[],
  totalPages: 1,
  loader: true,
  campaignCreatives: [],
  creatives: []
}

export const creativeLists = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data, total: action.total, totalPages: action.totalPages, loader: action.loader }
    case 'GET_DATA':
      return { ...state, allData: action.data, total: action.total, totalPages: action.totalPages }
    case 'GET_CREATIVE':
      return { ...state, allData: action.data, totalPages: action.totalPages, total: action.total, loader: action.loader }
    case 'FETCH_CREATIVE':
      return { ...state, selectedCreative: action.selectedCreative }
    case 'REMOVE_CREATIVE':
      return { ...state, selectedCreative: null }
    case 'GET_CAMP_FILTER':
      return { ...state, campaignFData: action.campaignFData }
    case 'GET_CRTV_FILTER':
      return { ...state, creativeFData: action.creativeFData }
    case 'GET_CAMP_ATTACH':
      return { ...state, campaignAttch: action.campaignAttch }
    case 'GET_CAMP_CR':
      return { ...state, campaignCreatives: action.campaignCreatives }
    case 'UPDATE_CREATIVE':
        return { ...state }
    case 'ADD_CREATIVE':
      return { ...state, creatives: action.creative }
    case 'DETACH_CREATIVES':
        return { ...state}
    case 'DETACH_CREATIVES_FROM_CAMPAIGNS':
        return { ...state}
    case 'RESET_REDUCER_STATE':
      return initialState
    default:
      return { ...state }
  }
}
