const initialState = {
  data: [],
  params: {},
  selectedApps: {},
  loader: true,
  total: 1
}

export const applists = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_APPS':
      return { ...state, data: action.data, totalPages: action.totalPages, total: action.total, loader: false }
    case 'SEARCH_APP':
      return { ...state, data: action.data, totalPages: action.totalPages, total: action.total, loader: false }
    case 'FETCH_APP':
      return { ...state, selectedApps: action.selectedApps }
    case 'CREATE_APP':
      return { ...state }
    case 'UPDATE_APP':
      return { ...state }
    case 'RESET_REDUCER_STATE':
      return initialState
    case 'DELETE_APP':
      return { ...state }
    default:
      return { ...state }
  }
}