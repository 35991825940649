const initialState = {
    data: [],
    total: 1,
    totalPages: 1,
    last: false,
    loader: true
}

export const siteapps = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_SITE_APPS':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, last: action.last, loader: false }
        case 'SEARCH_SITE_APPS':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, last: action.last, loader: false }
        default:
            return { ...state }
    }
}