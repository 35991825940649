const initialState = {
    singleData: {},
    data: [],
    total: 1,
    totalPages: 0,
    loader: true,
    existingBundles: []
}

export const mmpSettings = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_MMP_SETTINGS':
            return { ...state, data: action.data, total: action.total, totalPages: action.totalPages, loader: false }
        case 'ADD_SETTING':
            return { ...state }
        case 'GET_SETTING':
            return { ...state, singleData: action.data, loader: false }
        case 'UPDATE_SETTING':
            return { ...state }
        case 'GET_EXISTING_MMP':
            return { ...state, existingBundles: action.data }
        default:
            return { ...state }
    }
}
