const initialCampState = {
    data: [],
    campaignFData: [],
    total: 1,
    params: {},
    loader: true,
    search: false
}

export const campaignManager = (state = initialCampState, action) => {
    switch (action.type) {
        case 'GET_ALL_CAMP_DATA':
            return { ...state, data: action.data, total: action.total, loader: false }
        case 'POST_FILTER_CAMP':
            return { ...state, data: action.data, total: action.total, loader: false, search: action.search }
        case 'GET_CAMP_IDNAME':
            return { ...state, campaignFData: action.campaignFData }
        case 'SETTING_CAMP':
            return { ...state }
        default:
            return { ...state }
    }
}
