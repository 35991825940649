const initialState = {
    data: [],
    barData:[],
    impressions: [],
    clicks:[],
    winrate:[],
    estimate:{},
    params: {},
    loader: true
}

export const dashboardStats = (state = initialState, action) => {
    switch (action.type) {
        case ('GET_LINE_STATS'):
            if (action.params?.type === 'impressions') {
                return { ...state, impressions: action.data, params: action.params, loader: false }
            } else if (action.params?.type === 'clicks') {
                return { ...state, clicks: action.data, params: action.params, loader: false }
            } else {
                return { ...state, winrate: action.data, params: action.params, loader: false }
            }
        case ('GET_TABULAR_STATS'):
            return { ...state, data: action.data, params: action.params, loader: false }
        case ('GET_COLUMN_REPORT'):
            return { ...state, barData: action.data, loader: false }
        case ('GET_ESTIAMTE_STATS'):
            return { ...state, estimate: action.data, loader: false }
        default:
            return { ...state }
    }

}
