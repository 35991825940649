const initialCampState = {
    data: [],
    total: 1,
    loader: true
}

export const userAccounts = (state = initialCampState, action) => {
    switch (action.type) {
        case 'UPDATE_FUNDS':
            return { ...state }
        default:
            return { ...state }
    }
}