import { Play, AlertTriangle, X, Pause, Trash, PauseCircle, Check, Copy, Trash2 } from 'react-feather'
import { Tooltip, UncontrolledTooltip } from 'reactstrap'
import { format } from 'date-fns'
import Avatar from '@components/avatar'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios'
import { CSVLink } from 'react-csv'
import { Fragment, useEffect, useState } from 'react'
import { valid } from 'node-html-parser'
import { MdErrorOutline } from "react-icons/md"
import { BsHourglassSplit } from "react-icons/bs"


const prod = false
const MySwal = withReactContent(Swal)
export const defaultApiConfig = {
    apiEndpoint: prod ? 'https://ui-backendapi.mobavenue.com/api' : 'https://localhost:8080'
}

export const isCustomDomain = () => {
    return Boolean(window.location.hostname === 'lyxelflamingo.mobavenue.com')
}

export const formatQryDate = (value) => {
    if (!value) return value
    return format(value, 'yyyy-MM-dd')
}

export const formatTime = (value) => {
    if (!value) return value
    return format(value, 'HH:mm:ss')
}

export const parseQryDate = (value) => {
    if (!value) return value
    return new Date(value)
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Checks if an MultiArray is empty (returns boolean)
export const isMultiArrayEmpty = a => Array.isArray(a) && a.every(isMultiArrayEmpty)

// ** Returns K format from a number
export const kFormatter = num => (num > 10 ? `${(num / 1000).toFixed(1)}k` : num)
export const numFormatter = num => (new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(num))

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
// export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
//     if (!value) return value
//     return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
// }

// ** Returns short month of passed date
// export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
//     const date = new Date(value)
//     let formatting = { month: 'short', day: 'numeric' }

//     if (toTimeForCurrentDay && isToday(date)) {
//         formatting = { hour: 'numeric', minute: 'numeric' }
//     }

//     return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
// }

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const getDefaultFormattedDateFromPosix = (value) => {
    const u = 1000
    try {
        if (!value) return value
        return new Date(value * u)
    } catch (e) {
        return d
    }
}

export const getDateForPosix = (d, unit) => {
    const u = unit === 'SECONDS' ? 1000 : 1
    try {
        const date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(d * u)
        return date
    } catch (e) {
        return d
    }
}

export const getDateFromPosix = (d, unit) => {
    const u = unit === 'SECONDS' ? 1000 : 1
    try {
        const dtf = new Intl.DateTimeFormat('en-ZA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(d * u)
        return dtf
    } catch (e) {
        return d
    }
}

export const getTimeFromPosix = (d, unit) => {
    const u = unit === 'SECONDS' ? 1000 : 1
    try {

        const dtf = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false }).format(d * u)
        return dtf

    } catch (e) {
        return d
    }
}

// export const getDateTimeForPosix = (d, unit) => {
//     const u = unit === 'SECONDS' ? 1000 : 1
//     try {
//         const date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(d * u)
//         return date
//     } catch (e) {
//         return d
//     }
// }

export const disablePrevDateSelection = () => {
    const daysToDisabled = 90
    return new Date().setDate(new Date().getDate() - daysToDisabled)
}

export const getStatusAvatarTooltip = (s) => {
    switch (s) {
        case 'ACTIVE':
            return "Running"
        case 'INACTIVE':
            return "InActive"
        case 'PAUSE':
            return "Paused"
        case 'DELETE':
            return "Deleted"
        case 'EXPIRED':
            return "Expired"
        case 'DELETED':
            return "Deleted"
        case 'REJECTED':
            return "Rejected"
        case 'DEACTIVE':
            return "System Pause"
    }
}

export const getStatusAvatar = (s) => {

    switch (s) {
        case 'ACTIVE':
            return (<>
                <Avatar color="light-success" icon={<Play size={17} />} />
            </>)
        case 'INACTIVE':
            return (<>
                <Avatar color="light-warning" icon={<AlertTriangle size={17} />} />
            </>)
        case 'PAUSE':
            return (<>
                <Avatar color="light-primary" icon={<PauseCircle size={17} />} />
            </>)
        case 'DELETE':
            return (<>
                <Avatar color="light-danger" icon={<Trash size={17} />} />
            </>)
        case 'DELETED':
            return (<>
                <Avatar color="light-danger" icon={<Trash size={17} />} />
            </>)
        case 'REJECTED':
            return (<>
                <Avatar color="light-secondary" icon={<X size={17} />} />
            </>)
        case 'DEACTIVE':
            return (<>
                <Avatar color="light-dark" icon={<AlertTriangle size={17} />} />
            </>)
        case 'COMPLETED':
            return (<>
                <Avatar color="light-success" icon={<Check size={17} />} />
            </>)
        case 'IN_PROGRESS':
            return (<>
                <Avatar color="light-warning" icon={<BsHourglassSplit size={17} />} />
            </>)
        case 'FAILED':
            return (<>
                <Avatar color="light-danger" icon={<MdErrorOutline size={17} />} />
            </>)
        default:
            return ''
    }
}

export function getUpdateStatus(status) {
    switch (status) {
        case 'ACTIVE':
            return 'PAUSE'
        case 'PAUSE':
            return 'ACTIVE'
        case 'INACTIVE':
            return 'ACTIVE'
        default:
            return 'DELETED'
    }
}

export function handleStatus(row) {
    if (row.status === 'ACTIVE') return <PauseCircle className={'text-primary mr-25 mb-25'} size={16} />
    else if (row.status === 'PAUSE') return <Play className={'text-primary mr-25 mb-25'} size={16} />
    else if (row.status === 'INACTIVE') return <Play className={'text-primary mr-25 mb-25'} size={16} />
    else return <Trash2 size={16} />
}

export function getContentWithLimit(str, limit) {
    if (str !== undefined && str !== null) return str.length > limit ? `${str.substring(0, limit)}...` : str
    else return str

}

export const getAdType = (type) => {
    switch (type) {
        case 'BANNER':
            return 2
        case 'JS':
            return 3
        case 'RICHMEDIA':
            return 3
        case 'VIDEO':
            return 5
        default:
            return 0
    }
}

export const portraitSizes = '300x50, 320x50, 320x100, 300x1050, 320x480, 160x600, 300x600'
export const landscapeSizes = '468x60, 728x90, 480x320, 1080x1080, 1024x768, 640x360, 1200x627, 336x280, 768x1024'

export const getCreativeType = (size) => {

    if (portraitSizes.includes(size)) return 'PORTRAIT'
    else if (landscapeSizes.includes(size)) return 'LANDSCAPE'
    else return 'PORTRAIT'
}

export const getPhonePreview = (size) => {
    const portraitSizes = '300x50, 320x50, 320x100, 300x1050, 320x480, 160x600, 300x600'.split(",").map((sz) => { return sz.trim() })
    const landscapeSizes = '468x60, 728x90, 480x320, 1080x1080, 1024x768, 640x360, 1200x627, 336x280, 768x1024'.split(",").map((sz) => { return sz.trim() })

    if (portraitSizes.includes(size)) return <img className='portraitMobileImage' src='/images/iphone-portrait.png' />
    else if (landscapeSizes.includes(size)) return <img className='landscapeMobileImage' src='/images/iphone-landscape.png' />
    else return <img className='portraitMobileImage' src='/images/iphone-portrait.png' />
}

export const getCreativeDetails = (path, type) => {
    if (path === null || path === undefined || path === '') return ''
    else {
        if (type === 'EXTENSION') {
            const ex = path.match(/\.([^./]+)$/)?.[1] || ""
            return ex === 'jpg' ? 'jpeg' : ex
        } else if (type === 'FILENAME') {
            const match = path.match(/\/([^\/]+\.[^\/]+)$/)
            return match ? match[1] : ''
        }
    }
}

export async function getImgDimension(imgFile) {
    return new Promise(resolve => {
        const url = URL.createObjectURL(imgFile)
        const img = new Image()
        img.onload = function () {
            URL.revokeObjectURL(img.src)
            resolve({
                width: img.width,
                height: img.height
            })
        }
        img.src = url
    })
}

export const resolutionOptions = [
    { value: '320x480', label: '320x480' },
    { value: '320x50', label: '320x50' },
    { value: '160x600', label: '160x600' },
    { value: '600x600', label: '600x600' },
    { value: '300x600', label: '300x600' },
    { value: '768x1024', label: '768x1024' },
    { value: '300x250', label: '300x250' },
    { value: '300x50', label: '300x50' },
    { value: '320x100', label: '320x100' },
    { value: '468x60', label: '468x60' },
    { value: '728x90', label: '728x90' },
    { value: '300x1050', label: '300x1050' },
    { value: '1080x1080', label: '1080x1080' },
    { value: '480x320', label: '480x320' },
    { value: '1024x768', label: '1024x768' },
    { value: '640x360', label: '640x360' },
    { value: '1200x627', label: '1200x627' },
    { value: '336x280', label: '336x280' }
]

export const interstitialSizes = ['480x320', '1024x768', '320x480', '768x1024', '1080x1920', '1920x1080']

export const getToolTip = (elemId, elemTxt, staticTxt) => {
    const [tooleTipOpen, setToolTipOpen] = useState(false)
    const toggle = () => setToolTipOpen(!tooleTipOpen)
    const [copied, setCopied] = useState(true)

    useEffect(() => {
        if (!copied) {
            setTimeout(() => {
                setCopied(true)
            }, 2000)
        }
    }, [copied])

    return (
        <>
            {staticTxt ? <UncontrolledTooltip placement='bottom' target={elemId}>
                {elemTxt}
            </UncontrolledTooltip> : <Tooltip isOpen={tooleTipOpen}
                autohide={false}
                target={elemId}
                toggle={toggle}
            >
                {elemTxt} {copied ? <Copy
                    size={11}
                    onClick={() => {
                        setCopied(false)
                        navigator.clipboard.writeText(elemTxt)
                    }} /> : <Check size={11} />}
            </Tooltip>}
        </>
    )
}

export const handleConfirmCancel = async (handleDispatch, id) => {

    const result = await MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false
    })
    if (result.value) {
        handleDispatch(id)
        MySwal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Record has been deleted.',
            customClass: {
                confirmButton: 'btn btn-success'
            }
        })
    } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
            title: 'Cancelled',
            text: 'Delete aborted!',
            icon: 'error',
            customClass: {
                confirmButton: 'btn btn-success'
            }
        })
    }
}

export const handleEndcardConfirm = async (stepper) => {

    const result = await MySwal.fire({
        title: 'Video does not have an end card',
        text: "Although it is not mandatory to attach an End Card, it is highly recommended for better campaign performance.",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Go Back',
        confirmButtonText: 'CONTINUE',
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-primary ml-1'
        },
        buttonsStyling: false
    })
    if (result.value) {
        stepper.next()
    } else if (result.dismiss === MySwal.DismissReason.cancel) {

    }
}

export const handleCloneConfirmCancel = async (handleDispatch, id) => {

    const result = await MySwal.fire({
        title: 'Do you want to clone this Campaign?',
        text: "Ad creatives associated with this campaign will not be cloned.",
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false
    })
    if (result.value) {
        handleDispatch(id)
    }
}

export const handelDeleteConfirmCancel = async (updateStatus, row, component) => {

    const result = await MySwal.fire({
        title: `Do you want to delete this ${component}?`,
        text: 'Deleted action cannot be reverted',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false
    })
    if (result.value) {
        updateStatus('DELETE', row)
    }
}

// export const getImageDimensions = (setImageDimensions, imageUrl) => {
//     const img = new Image()
//     img.src = imageUrl

//     img.onload = () => {
//         setImageDimensions({
//             height: img.height,
//             width: img.width
//         })
//     }
//     img.onerror = (err) => {
//         console.log("img error")
//         console.error(err)
//     }
// }

export async function getImageSizeOfUrl(imageUrl) {
    return axios.get(imageUrl).then(resp => {
        return resp.headers["content-length"]
    }).catch((err) => {
        return 0
    })
}

export function prettyFileSize(bytes, separator = '', postFix = '') {
    if (bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1)
        return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`
    }
    return 'N/A'
}

// export const sspOptionsById = [

//     // { value: '3', label: 'MOBAVENUE-INHOUSE-SSP' },
//     { value: '7', label: 'SMARTYADS' },
//     { value: '12', label: 'SMAATO' },
//     { value: '21', label: 'APPODEAL' },
//     { value: '10', label: 'ADMIXER' },
//     { value: '13', label: 'GAMMA' },
//     { value: '16', label: 'BRAVE' },
//     { value: '17', label: 'RHYTHMONE' },
//     { value: '19', label: 'INMOBI' },
//     { value: '14', label: 'ADPOPCORN' },
//     { value: '29', label: 'MEITU' },
//     { value: '31', label: 'VENTESAVENUES' },
//     { value: '34', label: 'SURGEX' },
//     { value: '36', label: 'VERVE' },
//     { value: '37', label: 'TRAFFICJUNKY' },
//     { value: '41', label: 'YANDEX' },
//     { value: '42', label: 'ALGORIX' },
//     { value: '43', label: 'NATIVV' },
//     { value: '78', label: 'TRAFFICSTEER' },
//     { value: '80', label: 'FLATADS' },
//     { value: '94', label: 'PLAYSTREAM' },
//     { value: '98', label: 'LOOPME' },
//     { value: '101', label: 'ESKIMI' },
//     { value: '104', label: 'GREEDYGAMES' },
//     { value: '123', label: 'TRUECALLER' },
//     { value: '147', label: 'SOVRN' },
//     { value: '212', label: 'MINTEGRAL' }
// ]

export const sspOptions = [

    { value: 'SMARTYADS', label: 'SMARTYADS' },
    { value: 'SMAATO', label: 'SMAATO' },
    { value: 'APPODEAL', label: 'APPODEAL' },
    { value: 'ADMIXER', label: 'ADMIXER' },
    { value: 'GAMMA', label: 'GAMMA' },
    { value: 'BRAVE', label: 'BRAVE' },
    { value: 'RHYTHMONE', label: 'RHYTHMONE' },
    { value: 'INMOBI', label: 'INMOBI' },
    { value: 'ADPOPCORN', label: 'ADPOPCORN' },
    { value: 'MEITU', label: 'MEITU' },
    { value: 'VENTESAVENUES', label: 'VENTESAVENUES' },
    { value: 'SURGEX', label: 'SURGEX' },
    { value: 'VERVE', label: 'VERVE' },
    { value: 'TRAFFICJUNKY', label: 'TRAFFICJUNKY' },
    { value: 'YANDEX', label: 'YANDEX' },
    { value: 'ALGORIX', label: 'ALGORIX' },
    { value: 'NATIVV', label: 'NATIVV' },
    { value: 'TRAFFICSTEER', label: 'TRAFFICSTEER' },
    { value: 'FLATADS', label: 'FLATADS' },
    { value: 'PLAYSTREAM', label: 'PLAYSTREAM' },
    { value: 'LOOPME', label: 'LOOPME' },
    { value: 'ESKIMI', label: 'ESKIMI' },
    { value: 'GREEDYGAMES', label: 'GREEDYGAMES' },
    { value: 'TRUECALLER', label: 'TRUECALLER' },
    { value: 'SOVRN', label: 'SOVRN' },
    { value: 'MINTEGRAL', label: 'MINTEGRAL' }
]

export const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const urlRegex = new RegExp(
    "^(https?:\\/\\/)" + // protocol
    "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}" + // domain name
    "(:\\d{2,5})?" + // port
    "(\\/[\\w\\d\\u0020-\\u007E\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF.-]*)*" + // path
    "(\\?[\\w\\d\\u0020-\\u007E\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF=&-]*)?" + // query string
    "(#[\\w\\d\\u0020-\\u007E\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]*)?$", // fragment
    "i"
)
export const isValidURL = (url) => {
    return urlRegex.test(url)
}

export const getRole = (role) => {
    switch (role) {
        case '1':
            return 'ADVERTISER'
        case '2':
            return 'ADMIN'
        case '3':
            return 'ACCOUNT MANAGER'
        case '4':
            return 'SSP'
        default:
            return 'DELETED'
    }
}

export const statusOptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "PAUSE", label: "PAUSED" },
    { value: "DELETE", label: "DELETED" }
]

export const statusOptionsMin = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "DELETE", label: "DELETED" }
]

export const osOptions = [
    { value: 'ANDROID', label: 'Android' },
    { value: 'IOS', label: 'iOS' }
]

export const ExportCSV = (props) => {
    const { report, data, columns } = props
    const headers = columns.map(current => {
        return { label: current.header, key: current.field }
    })

    const currentDate = new Date().getFullYear().toString() +
        (new Date().getMonth() + 1).toString().padStart(2, '0') +
        new Date().getDate().toString().padStart(2, '0')

    let filename
    if (report === 'georeport') filename = `geo_report_${currentDate}`
    else if (report === 'adslotReport') filename = `ad_slot_${currentDate}`

    const filter = headers.filter(current => current.key !== undefined)

    return <CSVLink data={data} headers={filter} target="_blank" filename={filename}><span className='text-white'>EXPORT CSV</span></CSVLink>
}

export const removeSpaces = (value) => {
    const result = value.replace(/\s/g, '_')
    return result
}

export const validateHtmlTag = (adtag) => {

    //const parsedElem = parse(adtag)
    //https://www.npmjs.com/package/node-html-parser
    //Validate HTML tag using HTML parser,return true if the given adtag is valid, and return false if not
    //However it doesn't validate if we miss the open tag
    //console.log("valid(adtag, {comment: true}): ", valid(adtag, [{comment: true, voidTag: {addClosingSlash: false}, blockTextElements: {script: false, pre: false}}]))

    return valid(adtag)

    //return valid(value, { voidTag: {addClosingSlash: false}, blockTextElements: {script: false} })

    //const parser = new DOMParser()
    //const doc = parser.parseFromString(value, 'text/html')
    //const allnodes = doc.querySelectorAll('*')

    // for (let i = allnodes.length - 1; i >= 0; i--) {
    //     if (allnodes[i] instanceof HTMLUnknownElement) {
    //         return false
    //     } else //return true 
    // }

}

export const removeObjectProperty = (obj, property) => {
    const newObj = { ...obj }
    delete newObj[property]
    return newObj
}

export function formatNumbers(number) {
    const formatOptions = {
        notation: "compact",
        maximumFractionDigits: 3
    }
    const formatter = Intl.NumberFormat("en", formatOptions)
    return formatter.format(number)
}

export const SuccessToast = ({ operation, data }) => {
    return (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color='success' icon={<Check size={12} />} />
                    <h6 className='toast-title'>Succesfully {operation} {data}</h6>
                </div>
            </div>
        </Fragment>
    )
}

export const ErrorToast = ({ operation, data }) => {
    return (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color='danger' icon={<X size={12} />} />
                    <h6 className='toast-title'>Error while {operation} {data}</h6>
                </div>
            </div>
        </Fragment>
    )
}

export const SuccessToastMsg = ({ msg = 'Sucesss' }) => {
    return (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color='success' icon={<Check size={12} />} />
                    <h6 className='toast-title'>{msg}</h6>
                </div>
            </div>
        </Fragment>
    )
}

export const statusOptionsAPD = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "PAUSE", label: "PAUSED" },
    { value: "DELETE", label: "DELETED" }
]