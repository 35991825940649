// ** Initial user ability
import UserConfig from '../../utility/UserConfig'

const advertiser = () => {
    const { excludeSiteappReports, onlyReportingEnabled, includeQuartileColumns } = UserConfig()
    return [
        { action: 'read', subject: 'Auth' },
        { action: 'read', subject: 'reportsHeader' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'assetsHeader' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'manageHeader' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'analyseHeader' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'toolsHeader' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'dashboard' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'campaignList' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'campaignCreate' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'campaignEdit' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'creativeList' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'creativeCreate' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'creativeEdit' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'audienceList' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'audienceCreate' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'audienceEdit' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'appLists' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'appListCreate' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'appListEdit' },
        { action: 'read', subject: 'campaignReport' },
        { action: 'read', subject: 'creativeReport' },
        { action: excludeSiteappReports ? '' : 'read', subject: 'siteAppReportV2' },
        { action: !includeQuartileColumns ? '' : 'read', subject: 'customReport' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'deviceReport' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'toolsHeader' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'macros' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'support' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'accounts' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'billing' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'password' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'defaultPage' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'adQuality' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'privacyPolicy' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'dataPrivacyPolicy' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'optimization' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'newRule' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'editRule' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'bidMultiplier' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'newBidMultiplier' },
        { action: onlyReportingEnabled ? '' : 'read', subject: 'editBidMultiplier' },
        { action: !includeQuartileColumns ? '' : 'read', subject: 'customReportGenerate' },
        { action: !includeQuartileColumns ? '' : 'read', subject: 'customReportRegenerate' },
        { action: 'read', subject: 'exportLogsList' }
    ]
}

const admin = () => {
    return [
        { action: 'read', subject: 'Auth' },
        { action: 'read', subject: 'reportsHeader' },
        { action: 'read', subject: 'settingsHeader' },
        { action: 'read', subject: 'manageHeader' },
        { action: 'read', subject: 'analyseHeader' },
        { action: 'read', subject: 'adminDashboard' },
        { action: 'read', subject: 'manageUser' },
        { action: 'read', subject: 'campaignManager' },
        { action: 'read', subject: 'creativeManager' },
        { action: 'read', subject: 'userAccounts' },
        { action: 'read', subject: 'campaignCreate' },
        { action: 'read', subject: 'campaignEdit' },
        { action: 'read', subject: 'creativeCreate' },
        { action: 'read', subject: 'creativeEdit' },
        { action: 'read', subject: 'audienceCreate' },
        { action: 'read', subject: 'audienceEdit' },
        { action: 'read', subject: 'advertiserReport' },
        { action: 'read', subject: 'campaignReport' },
        { action: 'read', subject: 'creativeReport' },
        { action: 'read', subject: 'siteAppReportV2' },
        { action: 'read', subject: 'geoReport' },
        { action: 'read', subject: 'deviceReport' },
        { action: 'read', subject: 'publisherReport' },
        { action: 'read', subject: 'traffic&Revenue' },
        { action: 'read', subject: 'accounts' },
        { action: 'read', subject: 'billing' },
        { action: 'read', subject: 'manageReport' },
        { action: 'read', subject: 'manageReportCreate' },
        { action: 'read', subject: 'manageReportEdit' },
        { action: 'read', subject: 'adminTools' },
        { action: 'read', subject: 'toolsAdminHeader' },
        { action: 'read', subject: 'adminAudienceList' },
        { action: 'read', subject: 'adminAudienceCreate' },
        { action: 'read', subject: 'adminAudienceEdit' },
        { action: 'read', subject: 'adminAppLists' },
        { action: 'read', subject: 'adminBidMultiplier' },
        { action: 'read', subject: 'adminExportLogs' },
        { action: 'read', subject: 'appsflyerAudienceList' },
        { action: 'read', subject: 'appsflyerAudienceCreate' },
        { action: 'read', subject: 'appsflyerAudienceEdit' },
        { action: 'read', subject: 'defaultPage' },
        { action: 'read', subject: 'mmpSettingsList' },
        { action: 'read', subject: 'mmpSettingsCreate' },
        { action: 'read', subject: 'mmpSettingsEdit' },
        { action: 'read', subject: 'inventoryList' },
        { action: 'read', subject: 'adslotReport' },
        { action: 'read', subject: 'bidderDsList' },
        { action: 'read', subject: 'eventLogsList' },
        { action: 'read', subject: 'dealsList' },
        { action: 'read', subject: 'dealsCreate' },
        { action: 'read', subject: 'dealsEdit' },
        { action: 'read', subject: 'customReport' },
        { action: 'read', subject: 'customReportGenerate' },
        { action: 'read', subject: 'customReportRegenerate' }
    ]
}

const accountManager = () => {
    return [
        {
            action: 'read',
            subject: 'Auth'
        }
    ]
}

// ** Advertiser - 1, Admin - 2, Account Manager - 3, Publisher - 4
export const initialAbility = (role) => {
    switch (role?.toString()) {
        case '1':
            return advertiser()
        case '2':
            return admin()
        case '3':
            return accountManager()
        case '4':
            return publisher
        default:
            return [{ action: 'read', subject: 'defaultPage' }]

    }

}


export const _ = undefined
